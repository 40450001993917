.EvolvingForestCarousel {
  display: flex;
  justify-content: center;
  overflow-y: visible;
  overflow-x: hidden;

  @media (min-width: 481px) {
    &:hover {
      .btn {
        opacity: 1;
      }
    }
  }
}

.maxWidthContainer {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.container {
  width: 100%;
  margin: auto;
}

.slider {
  margin-bottom: 45px;
  overflow: visible;
  width: 100vw;

  @media (max-width: 600px) {
    width: 200vw;
    margin-left: -50vw;
  }
}

.slide {
  overflow: visible;
  // overflow-x: clip;
  transition: opacity 0.15s ease-in-out;

  &.fullscreen {
    transition: none;
    transform: none !important;

    .inner {
      transition: none;
    }
  }

  .inner {
    color: white;
    text-align: center;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.08) 100%
    );
    transition: transform 0.15s ease-in-out;
    position: relative;
    cursor: zoom-in;

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    &.fullscreen {
      background-color: #080414 !important;
      object-fit: contain;
      position: fixed !important;
      top: 0px !important;
      right: 0px !important;
      bottom: 0px !important;
      left: 0px !important;
      box-sizing: border-box !important;
      min-width: 0px !important;
      max-width: none !important;
      min-height: 0px !important;
      max-height: none !important;
      width: 100% !important;
      height: 100% !important;
      transform: none !important;
      margin: 0px !important;
      padding: 40px !important;
      z-index: 9999;
      cursor: zoom-out;

      @media (max-width: 600px) {
        padding: 0 !important;
      }
    
      img {
        position: relative;
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    
  }

  &:not(.active) {

    .inner {
      transform: scale(0.8);
    }
  }

  &.hidden {
    opacity: 0;
  }
}

.dots {
  text-align: center;
  margin-bottom: 45px;
}

.dot {
  border-radius: 9999px;
  margin: 0 4px;
  display: inline-flex;
  justify-content: center;
  align-self: center;
  padding: 0;
  overflow: hidden;

  .inner {
    content: "";
    display: block;
    height: 12px;
    width: 24px;
    background: #7657e623;
  }

  &.active {
    .inner {
      width: 46px;
      background: var(--purple);
    }
  }
}

.btn-container {
  margin-top: -50px;
  display: flex;
  justify-content: center;

  .center {
    justify-content: space-between;
    display: flex;
    width: 85vw;
  }
}

.btn {
  transition: 0.1s opacity ease, background 0.1s ease;
  opacity: 0;
  display: inline-flex;
  color: white;
  background: #7657e623;
  padding: 0;
  margin: 0;
  height: 50px;
  width: 50px;
  border-radius: 9999px;
  align-items: center;
  justify-content: center;

  &.left {
    transform: rotate(-180deg);
  }

  svg {
    width: 25px;
    height: 25px;
  }

  &:hover {
    background: var(--purple);
  }
}
