.TransparentCounter {
  display: flex;
  color: white;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 9999px;
  backdrop-filter: blur(18px);
  align-items: center;
  margin-top: 10px;

  .segment {
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    color: white;
    padding: 0;
    transition: transform .1s linear;

    &:active {
      transform: scale(.9);
    }
  }
}