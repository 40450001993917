.ArrowButton {
  background: var(--black-80);
  color: white;
  border-radius: 9999px;
  font-size: 16px;
  font-weight: 700;
  padding: 17px 72px 17px 32px;
  white-space: nowrap;
  position: relative;
  transition: .2s ease-in-out;

  .icon {
    transition: opacity .1s ease-in-out;

    &:nth-of-type(1) {
      right: 40px;
      opacity: 1;
    }
  
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    opacity: 0;
  }

  &:hover {
    background: #000234;

    .icon {
      &:nth-of-type(1) {
        opacity: 0;
      }

      opacity: 1;
    }
  }
}