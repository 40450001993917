.TeamCard {
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: white;

  a {
    color: var(--purple);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: transform .2s linear;
    transform-origin: center center;
  }

  &:hover {
    .img-container {
      transform: scale(1.05);
    }

    .bottom {
      background: #10162e;
    }
  }
}

.top {
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.img-container {
  transition: 0.1s ease-in transform;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(118, 87, 230, 0.1) 0%,
    rgba(118, 87, 230, 0.4) 100%
  );
}

.bottom {
  padding: 24px;
  background: var(--black-80);
  display: flex;
  justify-content: space-between;
  transition: 0.1s ease-in-out background;
}