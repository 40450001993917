.TopSection {
  height: 769px;
  position: relative;
  display: flex;
  align-items: flex-end;
  max-width: 1100px;
  width: 100%;
  margin: auto;
  justify-content: center;
  max-height: 100%;

  video, img {
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
  }
}

.mint {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 45px;
}

.center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.price {
  color: white;
  font-weight: 800;
  text-align: center;
  font-size: 18px;
  margin: 15px 0 12px;
}

.remaining {
  color: white;
  font-size: 18px;
  margin: 12px 0 25px;
}

.muteContainer {
  opacity: 1;
  transition: opacity 1s;
  pointer-events: all;
  
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}