.HeaderLogo {
  display: flex;
  align-items: center;
}

.text {
  font-weight: 700;
  margin-left: 10px;
  color: white;
  font-size: 24px;
  padding-top: 8px;

  @media (max-width: 400px) {
    margin-left: 5px;
    padding-top: 3px;
    font-size: 20px;
  }

  .full {
    display: block;

    @media (max-width: 700px) {
      display: none;
    }
  }

  .min {
    display: none;
    padding-right: 10px;

    @media (max-width: 700px) {
      display: block;
    }
  }
}

.logo {
  height: 35px;
  width: 35px;
}