.heading {
  text-align: center;

  h2 {
    font-weight: 800;
    font-size: 26px;
    color: white;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 42px;
}

.btn-gap {
  margin-left: 24px;
  margin-top: 24px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 32px;
  column-gap: 38px;

  @media (max-width: 950px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.select-container {
  border-radius: 18px;
  background: var(--black-75);
  padding: 24px 28px;
  margin-bottom: 42px;
  color: white;

  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 19px;

    h4 {
      font-weight: 700;
      font-size: 18px;
      margin: 0 0 16px 0;
    }

    p {
      font-size: 16px;
      color: white;
      opacity: 0.7;
      margin: 0;
    }
  }

  .details {
    display: flex;
    text-align: center;
    white-space: nowrap;

    h5 {
      margin: 0 0 12px 0;
      font-size: 14px;
    }

    .text {
      font-size: 18px;
    }

    .detail {
      &:nth-of-type(1) {
        padding-right: 25px;
        border-right: 1px solid rgba(255, 255, 255, 0.15);
      }

      &:nth-of-type(2) {
        padding-left: 25px;
      }
    }
  }

  .control {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 550px) {
    .top {
      flex-direction: column;
      margin-bottom: 25px;
    }

    h4 {
      text-align: center;
    }

    .left {
      margin-bottom: 20px;
    }

    .detail {
      width: 100%;
    }
    
    .details {
      white-space: normal;
    }

    .control {
      flex-direction: column-reverse;

    }
  }
}