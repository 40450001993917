@font-face {
  font-family: "Avenir";
  font-display: swap;
  font-weight: 100 300;
  src: url('./AvenirLight.ttf') format("opentype");
}

@font-face {
  font-family: "Avenir";
  font-display: swap;
  font-weight: 400 500;
  src: url('./AvenirMedium.ttf') format("opentype");
}

@font-face {
  font-family: "Avenir";
  font-display: swap;
  font-weight: 600 700;
  src: url('./AvenirSemiBold.ttf') format("opentype");
}

@font-face {
  font-family: "Avenir";
  font-display: swap;
  font-weight: 700 800;
  src: url('./AvenirBold.ttf') format("opentype");
}