
.Heading {
  margin-top: 30px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  font-size: 30px;
  font-weight: 800;
  font-family: Avenir;
}

.Center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container {
  width: 1040px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Guild {
  position: relative;
  width: 30%;
  border-radius: 8px;
  overflow: hidden;

  .ImgContainer {
    background-color: black;
    height: 550px;
    img {
      width: 100%;
      opacity: 1;
      transition: transform 0.5s, opacity 0.3s ease-in-out;
      transform: scale(1);
    }
  }
  
  .ImgText {
    width: 100%;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    background-color: #00000010;
    transition: color 0.3s ease-in-out;

    h2 {
      font-size: 30px;
    }

    p {
      font-size: 20px;
      line-height: 1.4;
    }
  }

  &:hover {
    .ImgContainer {
      img {
        opacity: 0.5;
        transform: scale(1.1);
      }
    }

    .ImgText {
      color: white;
    }
  }
}

