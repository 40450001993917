.Header {
  padding-top: 55px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  
  @media (max-width: 600px) {
    padding-top: 20px;
  }
}



.content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .balance {
    color: white; 
    font-weight: 900;
    margin-left: 10px; 
    text-align: center;
  
    @media (max-width: 440px) {
      font-size: 12px;
      margin-left: 0; 
    }

    @media (max-width: 330px) {
      display: none;
    }
  }

  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      height: 47px;
      width: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(18px);
      border-radius: 9999px;
      margin-right: 10px;

      @media (max-width: 600px) {
        margin-right: 5px;
      }

      @media (max-width: 440px) {
        height: 35px;
        width: 35px;
      }

      &:hover {
        background: #10162e;
      }

      svg {
        color: #fff;
        max-width: 20px;
        max-height: 20px;

        @media (max-width: 440px) {
          height: 15px;
          width: 15px;
        }
      }
    }

    .audio {
      margin-right: 0;
      margin-left: 10px;

      @media (max-width: 600px) {
        margin-left: 5px;
      }

      @media (max-width: 370px) {
        display: none;
      }
    }
  }
}

