.PlantItem {
  background: var(--black-75);
  border-radius: 28px;
  padding: 6px;

  .spinner-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-container {
    padding-bottom: 100%;
    border-radius: 24px;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: ease-in-out 0.5s opacity;
      opacity: 0;
    }

    img.visible {
      opacity: 1;
    }
  }

  .id {
    text-align: center;
    color: white;
    font-weight: 400;
    font-size: 16px;
    padding: 12px 0 6px;
  }

  .check-box {
    position: absolute;
    width: 28px;
    height: 28px;
    background: #1B173A;
    border-radius: 9999px;
    overflow: hidden;
    z-index: 1;
    top: 6px;
    right: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;

    &.checked {
      background: var(--purple);
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-image: url('../../../assets/TickIcon.svg');
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

.selectable {
  cursor: pointer;
}