@import-normalize;
@import url('./fonts/Avenir/avenir.css');

html,
body {
  margin: 0;
  background-color: #080826;
  font-family: 'Avenir';
}

* {
  box-sizing: border-box;
}

button {
  font-family: 'Avenir';
  border: none;
  cursor: pointer;
  background: none;
}

button:disabled {
  cursor: not-allowed;
}

a {
  text-decoration: none;
}

:root {
  --purple: #9f01e8;
  --grey-20: rgba(255, 255, 255, 0.2);
  --grey-70: rgba(255, 255, 255, 0.7);
  --black-75: #1B173A;
  --black-80: #0B0F20;
}