.top-section {
  padding-top: 140px;
  padding-bottom: 45px;
  border-radius: 0px 0px 56px 56px;
  overflow: hidden;
  background-image: linear-gradient(161.28deg, rgba(118, 87, 230, 0.06) 12.65%, rgba(118, 87, 230, 0.16) 87.35%), url('../assets/swirly-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 45px;
}

.dark-background {
  background: #000117
}