.split-text {
  display: flex;
  align-self: center;
  color: white;
  margin: 90px 0;
  justify-content: space-between;

  p, h2 {
    margin: 0;
    font-size: inherit;
  }

  p {
    opacity: 0.7;
  }

  .left {
    font-size: 45px;
    font-weight: 600;
    width: calc(35% - 20px);
    display: flex;
    align-items: center;
  }

  .right {
    width: calc(65% - 20px);
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: 100;
    line-height: 1.7;
  }

  @media (max-width: 780px) {
    display: block;
    text-align: center;
    margin: 45px 0;

    .left, .right {
      width: 100%;
      justify-content: center;
    }

    .left {
      margin-bottom: 20px;
      font-size: 30px;
    }

    .right {
      font-size: 16px;
    }
  }
}

.dark-background {
  background: #000117;
  display: flex;
  flex-direction: column;
  background-image: url('../assets/swirly-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.black-background {
  background: black;
  overflow: hidden;
}

.meet-our-team {
  margin-bottom: 90px;

  .team-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;

    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}