.FooterLogo {
  display: flex;
  align-items: center;
}

.text {
  font-weight: 700;
  margin-left: 10px;
  color: white;
  font-size: 26px;
}

.logo {
  height: 40px;
  width: 40px;
}
