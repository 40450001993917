.TransparentButton {
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(18px);
  border-radius: 9999px;
  padding: 8px 24px;
  appearance: none;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  font-size: 16px;
  padding: 14px 27px;
  min-width: 160px;

  @media (max-width: 600px) {
    min-width: 120px;
    font-size: 14px;
    padding: 14px 16px;
  }

  svg {
    max-width: 14px;
    max-height: 14px;
    margin-left: 10px;
  }
  .ShowOnHover {
    display: none;
  }

  &:hover {
    .ShowOnHover {
      display: inline;
    }
    .HideOnHover {
      display: none
    }

    background: #10162e;
  }
  
}