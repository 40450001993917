.PurpleButton {
  background: var(--purple);
  color: white;
  font-size: 16px;
  border-radius: 9999px;
  padding: 17px 47px;
  font-weight: 800;
  transition: box-shadow .2s ease-in-out;
  text-decoration: none;
  &:hover {
    box-shadow: 0px 6px 24px rgba(118, 87, 230, 0.5);
  }
}