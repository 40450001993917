.Details {
  color: white;
  margin-bottom: 50px;

  h3 {
    white-space: nowrap;
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 20px 0;
  }

  .text {
    white-space: nowrap;
    font-size: 26px;
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .line {
    background-image: linear-gradient(90deg, #911CDF00 0%, #911CDF 100%);
    height: 1px;
    width: 100%;

    &.right {
      background-image: linear-gradient(90deg, #911CDF 0%, #911CDF00 100%);
    }
  }

  .content {
    margin: 0 24px;
    text-align: center;

    .psci {
      background-image: url('../../../assets/PSCI.png');
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 30px 28px;
      padding-left: 40px;
    }
  }
}

.earnings {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .item {
    text-align: center;

    &:nth-of-type(2) {
      border-left: 1px solid rgba(255, 255, 255, 0.15);
      border-right: 1px solid rgba(255, 255, 255, 0.15);
    }
  }

  @media (max-width: 625px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .item {
      padding: 26px 0;
      width: fit-content;

      &:nth-of-type(2) {
        border-top: 1px solid rgba(255, 255, 255, 0.15);
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        border-left: none;
        border-right: none;
      }
    }
  }
}