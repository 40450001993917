.Roadmap {
  text-align: center;
  color: white;
  margin: 60px 0 0 0;

  h2 {
    font-size: 45px;
    font-weight: 800;
    margin: 0;
    margin-bottom: 40px;
  }

  p {
    margin: 0;
    font-weight: 200;
    line-height: 1.6;
    font-size: 18px;
    color: var(--grey-70);
  }
}

.intro {
  margin: auto;
  max-width: 600px;
  margin-bottom: 45px;
  width: 100%;
}

.maps {
  text-align: left;
  border-radius: 24px;
  overflow: hidden;

  .map {
    padding: 45px;
    background: #0B0F20;
    transition: filter .2s ease-in-out;

    @media (max-width: 780px) {
      padding: 25px;
      p {
        text-align: justify;
      }
    }

    .title {
      font-size: 26px;
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 26px;
    }

    .index {
      color: var(--purple);
    }
  
    .vr {
      border-left: 2px solid var(--grey-20);
      margin: 0 9px;
    }

    p {
      font-size: 16px;
    }

    &.selected {
      background: var(--purple);
      color: white !important;

      .index {
        color: white;
      }

      p {
        color: white;
      }
    }

    &:not(.selected) {
      &:hover {
        filter: brightness(1.5);
      }
    }
  }

  .map + .map {
    margin-top: 6px;
  }
}