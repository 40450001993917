.JoinTheCommunity {
  transform: translateY(50%);
  margin-top: -166px;
}

.content {
  background: var(--purple);
  border-radius: 24px;
  background-image: url('../../../assets/swirly-background.svg');
  background-size: contain;
  background-position: center;
  padding: 50px 80px;
  position: relative;
  overflow: hidden;
}

.details {
  width: 58%;
  color: white;
  z-index: 1;
  position: relative;

  h2 {
    margin: 0;
    font-size: 26px;
    margin-bottom: 8px;
  }

  p {
    color: var(--grey-70);
    margin: 0;
    line-height: 1.5;
    margin-bottom: 25px;
  }
}

.forest-img {
  position: absolute;
  bottom: -102px;
  right: -10px;
  width: 450px;
}

@media (max-width: 720px) {
  .content {
    padding: 25px;
  }

  .forest-img {
    bottom: -150px;
    right: -124px;
  }
}

@media (max-width: 540px) {
  .details {
    width: 100%;
    text-align: center;
  }

  .forest-img {
    bottom: 30px;
    right: 50px;
    transform: translate(50%, 50%);
    width: 200px;
  }
}