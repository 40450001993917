.Footer {
  padding-top: 40px;
  margin-top: 45px;
  background: var(--black-80);

  &.large {
    margin-top: 0;
    padding-top: 220px;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--grey-20);
  align-self: center;
  padding-bottom: 45px;

  .left {
    p {
      margin: 10px 0 0 0;
      color: var(--grey-70);
      font-size: 18px;
    }
  }

  .right {
    display: flex;
    align-items: center;
  }
}

.bot {
  text-align: center;
  padding: 30px 0;

  .cr {
    color: var(--grey-70);
    font-size: 18px;
    margin: 0;
    min-width: 180px;
  }

  .links {
    font-size: 14px;
    
    a {
      color: var(--grey-70);
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.icon {
  color: var(--purple);
  transition: color .1s ease-in-out;

  &:hover {
    color: #9A7EFF;
  }
}

.icon + .icon {
  margin-left: 20px;
}

@media (max-width: 480px) {
  .Footer {
    &.large {
      padding-top: 170px;
    }
  }

  .top {
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;

    .left {
      margin-bottom: 25px;
    }
  }
}
