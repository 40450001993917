.Button {
  padding: 12px 24px;
  overflow: hidden;
  border-radius: 9999px;
  color: white;
  font-weight: 700;
  font-size: 16px;

  &.primary {
    background: var(--purple);

    &:hover {
      filter: brightness(115%)
    }
  }

  &.small {
    padding: 6px 12px;
  }

  &.secondary {
    background: rgba(255, 255, 255, 0.1);

    &:hover {
      background-color: rgba(255, 255, 255, 0.16);
    }
  }
}